import { enableAllPlugins } from 'immer';
import { produce } from 'immer';
enableAllPlugins(); // 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export var initialState = { projectType: 'ONTHEWALL', projectId: '', tier: 'ENTERPRISE' }; //
export var actions = { SET_PROJECT: 'module/SET_PROJECT', SET_TIER: 'module/SET_TIER' }; // 액션 생성자
export var moduleActions = { setProjectAction: function setProjectAction(projectType, projectId) { return { type: actions.SET_PROJECT, payload: { projectType: projectType, projectId: projectId } }; }, setTierAction: function setTierAction(tier) { return { type: actions.SET_TIER, payload: { tier: tier } }; } }; // 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
var reducer = function reducer() { var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState; var action = arguments.length > 1 ? arguments[1] : undefined; return produce(state, function (draft) { switch (action.type) {
    case actions.SET_PROJECT:
        draft.projectType = action.payload.projectType;
        draft.projectId = action.payload.projectId;
        break;
    case actions.SET_TIER:
        draft.tier = action.payload.tier;
        break;
    default: break;
} }); };
export default reducer;
