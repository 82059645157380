import _objectSpread from "/Users/aa/Desktop/gdcomm/onthewall/otw-viewer/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { enableAllPlugins } from 'immer';
import { produce } from 'immer';
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus, resetStatus } from '@utils/reducerUtils';
import { i18n } from '../plugins/i18next';
enableAllPlugins(); // 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export var initialState = { clientId: null, globalData: { value: '', isWebLinkActive: false, posterLink: '', posterLinkEn: '', guideTextEn: '', guideText: '' }, menuButton: false,
    hasSize: true, hasView: true, hasIntro: false, screen: { width: null, orientation: window.screen.orientation ? window.screen.orientation.type.split('-')[0] : 'landscape' }, hasMenuButton: false, hasTitle: true, hasTag: false, isSpaceLoaded: false, isBGMLoaded: false, hasMenuOptions: { infoButton: true, chatButton: true, BGMButton: true, fullScreenButton: true // detailView: boolean;
    }, queryOption: { vr: false, dh: false, lang: 'ko', play: true, title: false }, globalOption: { infoButton: false, chatButton: false, BGMButton: true, webLinkModal: false, fullScreenButton: false, detailView: false,
        videoModal: false,
        componentPlaying: false, highlightButton: false, isObjectHovered: false, isSweepVisible: false, sweepIds: [], isHighDefinitionMode: false, language: i18n.language, pdfViewModal: false, detailedInfo: false, quizModal: false, treasureModal: false }, currentWebLink: null, wasPlayingMusic: false, playingComponentCount: 0,
    syncGlobalDataStatus: createStatus(), getGlobalDataStatus: createStatus(), updateGlobalDataStatus: createStatus() }; // 액션들의 집합.
export var actions = { SYNC_GLOBAL_DATA_REQUEST: 'SYNC_GLOBAL_DATA_REQUEST', SYNC_GLOBAL_DATA_SUCCESS: 'SYNC_GLOBAL_DATA_SUCCESS', SYNC_GLOBAL_DATA_FAILURE: 'SYNC_GLOBAL_DATA_FAILURE', GET_GLOBAL_DATA_REQUEST: 'GET_GLOBAL_DATA_REQUEST', GET_GLOBAL_DATA_SUCCESS: 'GET_GLOBAL_DATA_SUCCESS', GET_GLOBAL_DATA_FAILURE: 'GET_GLOBAL_DATA_FAILURE', UPDATE_GLOBAL_DATA_REQUEST: 'UPDATE_GLOBAL_DATA_REQUEST', UPDATE_GLOBAL_DATA_SUCCESS: 'UPDATE_GLOBAL_DATA_SUCCESS', UPDATE_GLOBAL_DATA_FAILURE: 'UPDATE_GLOBAL_DATA_FAILURE', UPDATE_GLOBAL_DATA_STATUS_RESET: 'UPDATE_GLOBAL_DATA_STATUS_RESET', SYNC_GLOBAL_DATA_DONE: 'SYNC_GLOBAL_DATA_DONE', SYNC_GET_POINTS_DONE: 'SYNC_GET_POINTS_DONE', TOGGLE_GLOBAL_OPTION_CHANGE: 'TOGGLE_GLOBAL_OPTION_CHANGE', TOGGLE_MENU_BUTTON: 'TOGGLE_MENU_BUTTON', SET_SCREEN_SIZE: 'SET_SCREEN_SIZE', INCREASE_PLAYING_COMPONENT_COUNT: 'INCREASE_PLAYING_COMPONENT_COUNT', SET_WAS_PLAYING_BGM: 'SET_WAS_PLAYING_BGM', SET_CURRENT_WEB_LINK: 'SET_CURRENT_WEB_LINK', SET_SPACE_LOADED: 'SET_SPACE_LOADED', SET_BGM_LOADED: 'SET_BGM_LOADED', SET_CLIENT_ID: 'SET_CLIENT_ID', SET_SWEEP_IDS: 'SET_SWEEP_IDS' };
export var setClientIdAction = function setClientIdAction(clientId) { return { type: actions.SET_CLIENT_ID, clientId: clientId }; }; // 액션 크리에이터. dispatch 내부에서 사용.
export var getGlobalDataAction = function getGlobalDataAction(exhibitionId) { return { type: actions.GET_GLOBAL_DATA_REQUEST, exhibitionId: exhibitionId }; };
export var increasePlayingComponent = function increasePlayingComponent() { return { type: actions.INCREASE_PLAYING_COMPONENT_COUNT }; }; //인자로 가진 globalOption을 켜고 끄는 액션
export var toggleGlobalOptionChangeAction = function toggleGlobalOptionChangeAction(target) { var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null; return { type: actions.TOGGLE_GLOBAL_OPTION_CHANGE, target: target, value: value }; };
export var toggleMenuButtonAction = function toggleMenuButtonAction() { var forced = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false; return { type: actions.TOGGLE_MENU_BUTTON, forced: forced }; };
export var syncGlobalDataAction = function syncGlobalDataAction() { return { type: actions.SYNC_GLOBAL_DATA_REQUEST }; };
export var updateGlobalDataAction = function updateGlobalDataAction(target, value) { return { type: actions.UPDATE_GLOBAL_DATA_REQUEST, target: target, value: value }; };
export var syncGlobalDataDoneAction = function syncGlobalDataDoneAction() { return { type: actions.SYNC_GLOBAL_DATA_DONE }; };
export var resetStatusAction = function resetStatusAction(type) { return { type: type }; };
export var setScreenSizeAction = function setScreenSizeAction(width, orientation) { return { type: actions.SET_SCREEN_SIZE, width: width, orientation: window.screen.orientation ? window.screen.orientation.type.split('-')[0] : 'landscape' }; };
export var setWasPlayingBGMAction = function setWasPlayingBGMAction(value) { return { type: actions.SET_WAS_PLAYING_BGM, value: value }; };
export var setCurrentWebLink = function setCurrentWebLink(data) { return { type: actions.SET_CURRENT_WEB_LINK, data: data }; };
export var setSpaceLoaded = function setSpaceLoaded() { return { type: actions.SET_SPACE_LOADED }; };
export var setBGMLoaded = function setBGMLoaded() { return { type: actions.SET_BGM_LOADED }; };
export var setSweepIds = function setSweepIds(data) { return { type: actions.SET_SWEEP_IDS, data: data }; }; // 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
var reducer = function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return produce(state, function (draft) {
        var _action$hasSize, _action$hasTag, _action$hasMenuOption, _action$hasMenuOption2, _action$hasMenuOption3, _action$hasMenuOption4, _action$hasMenuOption5, _action$hasMenuOption6, _action$hasMenuOption7, _action$hasMenuOption8, _action$globalOption$, _action$globalOption, _action$globalOption$2, _action$globalOption2, _action$globalOption$3, _action$globalOption3;
        switch (action.type) {
            case actions.SET_CLIENT_ID:
                draft.clientId = action.clientId;
                break;
            case actions.SYNC_GLOBAL_DATA_REQUEST:
                draft.syncGlobalDataStatus = createRequestStatus();
                break;
            case actions.SYNC_GLOBAL_DATA_SUCCESS:
                draft.syncGlobalDataStatus = createSuccessStatus();
                draft.globalData = action.data;
                break;
            case actions.SYNC_GLOBAL_DATA_FAILURE:
                draft.syncGlobalDataStatus = createFailureStatus(action.error);
                break;
            case actions.GET_GLOBAL_DATA_REQUEST:
                draft.getGlobalDataStatus = createRequestStatus();
                break;
            case actions.GET_GLOBAL_DATA_SUCCESS:
                draft.getGlobalDataStatus = createSuccessStatus();
                draft.globalData = action.globalData;
                draft.hasSize = (_action$hasSize = action.hasSize) !== null && _action$hasSize !== void 0 ? _action$hasSize : true;
                draft.hasTag = (_action$hasTag = action.hasTag) !== null && _action$hasTag !== void 0 ? _action$hasTag : false;
                draft.hasMenuOptions.infoButton = (_action$hasMenuOption = (_action$hasMenuOption2 = action.hasMenuOptions) === null || _action$hasMenuOption2 === void 0 ? void 0 : _action$hasMenuOption2.infoButton) !== null && _action$hasMenuOption !== void 0 ? _action$hasMenuOption : true;
                draft.hasMenuOptions.chatButton = (_action$hasMenuOption3 = (_action$hasMenuOption4 = action.hasMenuOptions) === null || _action$hasMenuOption4 === void 0 ? void 0 : _action$hasMenuOption4.chatButton) !== null && _action$hasMenuOption3 !== void 0 ? _action$hasMenuOption3 : true;
                draft.hasMenuOptions.BGMButton = (_action$hasMenuOption5 = (_action$hasMenuOption6 = action.hasMenuOptions) === null || _action$hasMenuOption6 === void 0 ? void 0 : _action$hasMenuOption6.BGMButton) !== null && _action$hasMenuOption5 !== void 0 ? _action$hasMenuOption5 : true;
                draft.hasMenuOptions.fullScreenButton = (_action$hasMenuOption7 = (_action$hasMenuOption8 = action.hasMenuOptions) === null || _action$hasMenuOption8 === void 0 ? void 0 : _action$hasMenuOption8.fullScreenButton) !== null && _action$hasMenuOption7 !== void 0 ? _action$hasMenuOption7 : true; // draft.globalOption.infoButton = action.globalOption.infoButton ?? false;
                draft.globalOption.BGMButton = (_action$globalOption$ = (_action$globalOption = action.globalOption) === null || _action$globalOption === void 0 ? void 0 : _action$globalOption.BGMButton) !== null && _action$globalOption$ !== void 0 ? _action$globalOption$ : true;
                draft.globalOption.chatButton = (_action$globalOption$2 = (_action$globalOption2 = action.globalOption) === null || _action$globalOption2 === void 0 ? void 0 : _action$globalOption2.chatButton) !== null && _action$globalOption$2 !== void 0 ? _action$globalOption$2 : true;
                draft.globalOption.fullScreenButton = (_action$globalOption$3 = (_action$globalOption3 = action.globalOption) === null || _action$globalOption3 === void 0 ? void 0 : _action$globalOption3.fullScreenButton) !== null && _action$globalOption$3 !== void 0 ? _action$globalOption$3 : false;
                draft.globalOption.language = i18n.language;
                draft.hasTitle = true;
                if (action.queryOption)
                    draft.queryOption = _objectSpread(_objectSpread({}, draft.queryOption), action.queryOption);
                if (action.isPlatform) {
                    draft.hasMenuButton = true;
                    draft.globalOption.BGMButton = true;
                    draft.hasTitle = true;
                    draft.hasView = true;
                }
                if (action.isCustomized) {
                    var _action$globalOption$4, _action$globalOption4, _action$hasMenuButton, _action$hasTitle, _action$globalOption$5, _action$globalOption5, _action$globalOption$6, _action$globalOption6;
                    draft.globalOption.BGMButton = (_action$globalOption$4 = (_action$globalOption4 = action.globalOption) === null || _action$globalOption4 === void 0 ? void 0 : _action$globalOption4.BGMButton) !== null && _action$globalOption$4 !== void 0 ? _action$globalOption$4 : true;
                    draft.hasMenuButton = (_action$hasMenuButton = action.hasMenuButton) !== null && _action$hasMenuButton !== void 0 ? _action$hasMenuButton : true;
                    draft.hasTitle = (_action$hasTitle = action.hasTitle) !== null && _action$hasTitle !== void 0 ? _action$hasTitle : true;
                    draft.globalOption.isSweepVisible = (_action$globalOption$5 = (_action$globalOption5 = action.globalOption) === null || _action$globalOption5 === void 0 ? void 0 : _action$globalOption5.isSweepVisible) !== null && _action$globalOption$5 !== void 0 ? _action$globalOption$5 : false;
                    draft.globalOption.isHighDefinitionMode = (_action$globalOption$6 = (_action$globalOption6 = action.globalOption) === null || _action$globalOption6 === void 0 ? void 0 : _action$globalOption6.isHighDefinitionMode) !== null && _action$globalOption$6 !== void 0 ? _action$globalOption$6 : false; // highDefinitionMode은 모바일에서 실행될 수 없음.
                    // if (action.globalOption?.isHighDefinitionMode !== null) {
                    //   draft.globalOption.isHighDefinitionMode = !isMobile() && action.globalOption.isHighDefinitionMode;
                    // }
                } // query string, search 추가함
                // chat, bgm, hd 으로 초기설정 가능하며,
                // title, menu로 영구 설정 가능함.
                var search = new URLSearchParams(window.location.search);
                if (search.get('chat') === '0') {
                    draft.globalOption.chatButton = false;
                }
                if (search.get('bgm') === '0') {
                    draft.globalOption.BGMButton = false;
                }
                if (search.get('hd') === '1') {
                    draft.globalOption.isHighDefinitionMode = true;
                }
                if (search.get('title') === '0') {
                    draft.hasTitle = true;
                }
                if (search.get('menu') === '0') {
                    draft.globalOption.chatButton = false;
                    draft.hasMenuButton = false;
                    draft.globalOption.BGMButton = false;
                }
                break;
            case actions.GET_GLOBAL_DATA_FAILURE:
                draft.getGlobalDataStatus = createFailureStatus(action.error);
                break;
            case actions.UPDATE_GLOBAL_DATA_REQUEST:
                draft.updateGlobalDataStatus = createRequestStatus();
                break;
            case actions.UPDATE_GLOBAL_DATA_SUCCESS:
                draft.updateGlobalDataStatus = createSuccessStatus();
                break;
            case actions.UPDATE_GLOBAL_DATA_FAILURE:
                draft.updateGlobalDataStatus = createFailureStatus(action.error);
                break;
            case actions.UPDATE_GLOBAL_DATA_STATUS_RESET:
                draft.updateGlobalDataStatus = resetStatus();
                break;
            case actions.TOGGLE_GLOBAL_OPTION_CHANGE:
                if (action.value !== null) {
                    draft.globalOption[action.target] = action.value;
                }
                else {
                    draft.globalOption[action.target] = !draft.globalOption[action.target];
                }
                break;
            case actions.TOGGLE_MENU_BUTTON:
                draft.menuButton = !draft.menuButton;
                break;
            case actions.SET_SCREEN_SIZE:
                draft.screen.width = action.width;
                if (action.width === 'xs' && document.body.clientHeight > document.body.clientWidth) {
                    draft.screen.orientation = 'portrait';
                }
                else
                    draft.screen.orientation = action.orientation;
                break;
            case actions.INCREASE_PLAYING_COMPONENT_COUNT:
                draft.playingComponentCount += 1;
                break;
            case actions.SET_WAS_PLAYING_BGM:
                draft.wasPlayingMusic = action.value;
                break;
            case actions.SET_CURRENT_WEB_LINK:
                draft.currentWebLink = action.data;
                break;
            case actions.SET_SPACE_LOADED:
                draft.isSpaceLoaded = true;
                break;
            case actions.SET_BGM_LOADED:
                draft.isBGMLoaded = true;
                break;
            case actions.SET_SWEEP_IDS:
                draft.globalOption.sweepIds = action.data;
                break;
            default: break;
        }
    });
};
export default reducer;
